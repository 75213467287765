export const APP_VERSION = "3.1.0";

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

export const loginURL = `${process.env.REACT_APP_SSO_URL}/sso/superadmin/login`;

export const permissionURL = process.env.REACT_APP_PERMISSION_URL;
export const ssoURL = process.env.REACT_APP_SSO_URL;
export const APIURL = process.env.REACT_APP_API_URL;

export const marketplaceURL = process.env.REACT_APP_MARKETPLACE_URL;
export const cmsURL = process.env.REACT_APP_CMS_URL;
export const blobURL = process.env.REACT_APP_BLOB_URL;
export const termsURL = process.env.REACT_APP_TERMS_URL;
export const surveyURL = process.env.REACT_APP_SURVEY_URL;

export const confirmLoginURL = process.env.REACT_APP_CONFIRM_LOGIN_URL;
export const roleURL = process.env.REACT_APP_ROLE_URL;
export const refreshTokenURL = `${ssoURL}/sso/global/refresh`;
export const protocolURL = process.env.REACT_APP_PROTOCOL_URL;
