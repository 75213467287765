import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "src/theme";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  primaryButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    // backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    marginBottom: theme.spacing(1),
    padding: "10px 40px",
    borderRadius: "8px !important",
    border: "none",
    fontSize: "16px",
    whiteSpace: "nowrap",
    "&:hover": {
      cursor: "pointer",
    },
  },
  disabled: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
    "&:hover": {
      cursor: "default",
    },
  },
}));

interface PrimaryButtonProps {
  disabled: boolean;
  text: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  disabled,
  text,
  onClick = null,
  type = "button",
  style,
}: PrimaryButtonProps) => {
  const classes = useStyles();

  return (
    <>
      <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={clsx(classes.primaryButton, disabled && classes.disabled)}
        style={style ?? {}}
      >
        {text}
      </button>
    </>
  );
};

export default PrimaryButton;
