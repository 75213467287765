import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    exact: true,
    path: "/500",
    component: lazy(() => import("src/views/errors/500")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    path: "/login-unprotected",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app/home",
        component: lazy(() => import("src/views/home/ItemCard")),
      },
      {
        exact: true,
        path: "/app/protocol/create",
        component: lazy(() => import("src/views/protocol/create/index")),
      },
      {
        exact: true,
        path: "/app/protocol/view",
        component: lazy(() => import("src/views/protocol/view/index")),
      },
      {
        exact: true,
        path: "/app/protocol/view/:id",
        component: lazy(() => import("src/views/protocol/protocolView/index")),
      },
      {
        exact: true,
        path: "/app/secondary/view",
        component: lazy(() => import("src/views/secondary/view/index")),
      },
      {
        exact: true,
        path: "/app/secondary/view/:id",
        component: lazy(
          () => import("src/views/secondary/secondaryView/index")
        ),
      },
      {
        exact: true,
        path: "/app/secondary/create",
        component: lazy(() => import("src/views/secondary/create/index")),
      },
      {
        exact: true,
        path: "/app/survey/view",
        component: lazy(() => import("src/views/survey/view/index")),
      },
      {
        exact: true,
        path: "/app/survey/view/:id",
        component: lazy(() => import("src/views/survey/surveyView")),
      },
      {
        exact: true,
        path: "/app/survey/create",
        component: lazy(() => import("src/views/survey/create")),
      },
      {
        exact: true,
        path: "/app/marketplace/view",
        component: lazy(() => import("src/views/marketplace/view/")),
      },
      {
        exact: true,
        path: "/app/marketplace/view/:id",
        component: lazy(() => import("src/views/marketplace/productView/")),
      },
      {
        exact: true,
        path: "/app/marketplace/create",
        component: lazy(() => import("src/views/marketplace/addProduct/")),
      },
      {
        exact: true,
        path: "/app/marketplace/edit/:id",
        component: lazy(
          () => import("src/views/marketplace/addProduct/editProduct")
        ),
      },
      {
        exact: true,
        path: "/app/invite",
        component: lazy(() => import("src/views/invite")),
      },
      {
        exact: true,
        path: "/app/documents/view",
        component: lazy(() => import("src/views/documents/view")),
      },
      {
        exact: true,
        path: "/app/documents/view/:id",
        component: lazy(() => import("src/views/documents/documentView")),
      },
      {
        exact: true,
        path: "/app/documents/create",
        component: lazy(() => import("src/views/documents/createDocument")),
      },
      {
        exact: true,
        path: "/app/documents/edit/:id",
        component: lazy(
          () => import("src/views/documents/createDocument/editDocument")
        ),
      },
      {
        exact: true,
        path: "/app/cms/create",
        component: lazy(() => import("src/views/cms/create")),
      },
      {
        exact: true,
        path: "/app/cms/manage",
        component: lazy(() => import("src/views/cms/manage")),
      },
      {
        exact: true,
        path: "/app/cms/manage/edit/:tagType/:contentType",
        component: lazy(
          () => import("src/views/cms/manage/createCategorization")
        ),
      },
      {
        exact: true,
        path: "/app/cms/manage/create/:tagType",
        component: lazy(
          () => import("src/views/cms/manage/createCategorization")
        ),
      },
      {
        exact: true,
        path: "/app/cms/create/:type",
        component: lazy(() => import("src/views/cms/create/createContent")),
      },
      {
        exact: true,
        path: "/app/cms/view/:type",
        component: lazy(() => import("src/views/cms/view/listContent")),
      },
      {
        exact: true,
        path: "/app/cms/view/:type/:id",
        component: lazy(() => import("src/views/cms/view/viewContent")),
      },
      {
        exact: true,
        path: "/app/cms/edit/:edit/:id",
        component: lazy(() => import("src/views/cms/view/editContent")),
      },
      {
        exact: true,
        path: "/app/offers",
        component: lazy(() => import("src/views/offers/index")),
      },
      {
        exact: true,
        path: "/app/offers/create",
        component: lazy(() => import("src/views/offers/create")),
      },
      {
        exact: true,
        path: "/app/contracts",
        component: lazy(() => import("src/views/contracts/index")),
      },
      {
        exact: true,
        path: "/app/contract/create",
        component: lazy(() => import("src/views/contracts/create")),
      },
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/protocol/view" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
