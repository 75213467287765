import Utils from "@admincypios/utils";
import {CypiosVersion, getLastRequestId} from "./contexts/JWTAuthContext";

const REPORT_URL = process.env.REACT_APP_REPORT_URL;

let crashReported = false;

const GlobalErrorHandler = (event: ErrorEvent | Event) => {
  const requestId = getLastRequestId();
  event.preventDefault();
  const error = event instanceof ErrorEvent ? event.error : event;
  console.error(error);
  console.error("Unhandled error, please report this to the developers");

  if (REPORT_URL?.length) {
    if (!crashReported) {
      crashReported = true;
      fetch(REPORT_URL, {
        method: "POST",
        body: JSON.stringify({
          url: window.location.href,
          clientTime: Utils.datetime.nowISOstring(),
          code: 500,
          message: error.message,
          context: CypiosVersion,
          ssoId: localStorage.getItem("ssoId"),
        }),
      })
        .then(() => {
          console.log(`Report for crash ${requestId} sent`);
        })
        .catch(() => {
          console.log("Failed to send the report for request " + requestId);
        })
        .finally(() => window.location.replace("/500"));
    } else {
      console.log("Crash already reported, not sending another report");
      window.location.replace("/500");
    }
  } else {
    console.log("REPORT_URL not set, crash reports will not be sent");
    window.location.replace("/500");
  }

  return false;
};

export default GlobalErrorHandler;
