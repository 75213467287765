import React from "react";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
  Typography, useMediaQuery
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Menu as MenuIcon } from "react-feather";
import Logo from "src/components/Logo";
import { THEMES } from "src/constants";
import type { Theme } from "src/theme";
import Account from "./Account";

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
    "& .MuiToolbar-root": {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  toolbar: {
    minHeight: "80px !important",
    backgroundColor: "#2AACA4",
    color: theme.palette.text.secondary,
  },
  logoAndBaseline: {
    marginLeft: theme.spacing(3),
  },
  title: {
    fontWeight: "900 !important",
    fontSize: "22px !important",
    color: `${theme.palette.text.secondary} !important`,
  },
  subtitle: {
    fontWeight: "800 !important",
    fontSize: "12px !important",
    display: "inline",
    color: `${theme.palette.text.secondary} !important`,
  }
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const hidden = useMediaQuery("(max-width:1200px)");

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box hidden={!hidden}>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box className={classes.logoAndBaseline}>
          <Typography variant="h5" className={classes.title}>CYPIOSdiet</Typography>
          <Typography variant="h5" className={classes.subtitle} fontSize={"16px !important"}>Plateforme de gestion des contenus</Typography>
        </Box>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
