import React from 'react';
import type { FC } from 'react';

interface MarketPlaceIconProps {
  [key: string]: any;
}

const MarketPlaceIcon: FC<MarketPlaceIconProps> = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/icons/marketplace.svg"
      {...props}
    />
  );
}

export default MarketPlaceIcon;
