import React from "react";
import type { FC } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import { jssPreset, StylesProvider } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GlobalStyles from "src/components/GlobalStyles";
import ScrollReset from "src/components/ScrollReset";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import useSettings from "src/hooks/useSettings";
import { createTheme } from "src/theme";
import CacheBuster from "./CacheBusting/CacheBuster";
import routes, { renderRoutes } from "src/routes";
import GlobalErrorHandler from "./error-handler";

window.addEventListener("error", GlobalErrorHandler)

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              if (process.env.NODE_ENV !== "development")
                refreshCacheAndReload();
            }
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider dense maxSnack={3}>
                  <Router history={history}>
                    <AuthProvider>
                      <GlobalStyles />
                      <ScrollReset />

                      {renderRoutes(routes)}
                    </AuthProvider>
                  </Router>
                </SnackbarProvider>
              </LocalizationProvider>
            );
          }}
        </CacheBuster>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
