import React from 'react';
import type { FC } from 'react';

interface FileDocumentIconProps {
  [key: string]: any;
}

const FileDocumentIcon: FC<FileDocumentIconProps> = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/icons/file-document.svg"
      {...props}
    />
  );
}

export default FileDocumentIcon;
