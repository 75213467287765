import type { FC, ReactNode } from "react";
import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import SplashScreen from "src/components/SplashScreen";
import fetch from "node-fetch";
import { loginURL } from "src/constants";
import { useSnackbar } from "notistack";
import packageJson from "../../package.json";
import Utils from "@admincypios/utils";
import {v4} from "uuid";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: any | null;
}

const appVersion = packageJson.version;

function get_browser() {
  try {
    let ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  } catch {
    return null;
  }
}

const browser = get_browser();

const reportURL = process.env.REACT_APP_REPORT_URL;


export const CypiosVersion = `${window?.navigator?.platform ?? "web"}:${
  browser?.name ?? "unknown"
}${browser.version ?? "x.x"}/${appVersion}`;


let lastRequestId = null;

export const getLastRequestId = () => lastRequestId;

export const myFetch = async (route: string, opt?: any) => {
  try {
    const date = new Date();
    const clientTime = Utils.datetime.nowISOstring()
    const requestId = v4()
    lastRequestId = requestId;
    const jwt = localStorage.getItem("accessToken");
    if (window.location.pathname !== "/login") {
      // const requestTime = Number(localStorage.getItem("requestTime"));
      // if (date.getTime() - requestTime > 1200000) {
      //   setSession(null);
      //   return undefined;
      //}
    }
    const body = {
      ...(opt ?? {} ),
      ...{
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${jwt}`,
          version: CypiosVersion,
          "client-time": clientTime,
          "request-id": requestId,
        },
      },
    };
    const res = await fetch(route, body);


    if (!res.ok) {
      console.log("Request failed " + res.status);

      if (reportURL?.length) {
        fetch(reportURL, {
          method: "POST",
          body: JSON.stringify({
            url: route,
            "client-time": clientTime,
            "request-id": requestId,
            version: CypiosVersion,
            code: res.status,
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": jwt ? "Bearer " + jwt : null
          }
        })
          .then(() => console.log(`Report for request ${requestId} sent`))
          .catch(() =>
            console.log("Failed to send the report for request " + requestId)
          );
      } else {
        console.warn("WARNING : REPORT_URL is not set, cannot send report to server");
      }
    }
    
    if (res.status === 403 || res.status === 500) {
      setSession(null);
      return res;
    }
    localStorage.setItem("requestTime", String(date.getTime()));

    return res;
  } catch (ex) {
    console.log(ex);
    //  setSession(null);
    return undefined;
  }
};

interface AuthContextValue extends AuthState {
  method: "JWT";
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: "INITIALISE";
  payload: {
    isAuthenticated: boolean;
    user: any | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: any;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    user: any;
  };
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    console.log("set token");
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
    const path = window.location.pathname;
    const search = window.location.search;
    //@ts-ignore
    window.location = `/login?${path}${search}`;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();

  const login = async (email: string, password: string) => {
    console.log("login");
    const res = await myFetch(`${loginURL}`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    if (res.ok) {
      const jsonRes = await res.json();
      const { accessToken } = jsonRes;
      if (!accessToken) {
        return;
      }
      // @todo change it
      setSession(accessToken);
      const user = {
        id_userPro: 1,
        firstname: "admin",
        lastname: "admin",
        mail: "admin",
      };
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } else {
      const data = await res.json();
      if (typeof data.message === "string") {
        enqueueSnackbar(data.message, { variant: "error" });
      } else if (typeof data.message === "object") {
        enqueueSnackbar(data.message[0], { variant: "error" });
      } else {
        enqueueSnackbar("Erreur inconnue", { variant: "error" });
      }
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const user = {
            id_userPro: 1,
            firstname: "admin",
            lastname: "admin",
            mail: "admin",
          };
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
