import React, { useRef } from "react";
import type { FC } from "react";
import {
  Box,
  ButtonBase,
  Hidden,
  Typography
} from "@mui/material";
import useAuth from "src/hooks/useAuth";
import ChevronDownIconImg from "src/components/iconComponents/ChevronDownIconImg";

interface AccountHeaderProps {
  isOpen: boolean;
  eventHandler: any;
  textStyle: any;
  iconStyle: any;
}

const AccountHeader: FC<AccountHeaderProps> = (props: AccountHeaderProps) => {
  const ref = useRef<any>(null);
  const { user } = useAuth();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={props.eventHandler}
        // @ts-ignore
        ref={ref}
      >
        <Hidden smDown>
          <Typography
            variant="h5"
            color="inherit"
            className={props.textStyle}
          >
            {user.firstname}
            {"  "}
            {user.lastname}
          </Typography>
          <div
            className={props.iconStyle}
          >
            <ChevronDownIconImg />
          </div>
        </Hidden>
      </Box>
    </>
  );
};

export default AccountHeader;
