/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import type {FC, ReactNode} from "react";
import React, { useEffect } from "react";
import {Link as RouterLink, matchPath, useLocation} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {
  Box,
  Drawer,
  List,
  ListSubheader,
  useMediaQuery
} from "@mui/material";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import { Theme } from "src/theme";
import FileDocumentIcon from "src/components/iconComponents/FileDocumentIcon";
import FolderAddIcon from "src/components/iconComponents/FolderAddIcon";
import ListIcon from "src/components/iconComponents/ListIcon";
import UnionIcon from "src/components/iconComponents/UnionIcon";
import MarketplaceIcon from "src/components/iconComponents/MarketplaceIcon";
import { createBrowserHistory } from "history";
import PrimaryButton from "../../../components/PrimaryButton";
import NewspaperIcon from "../../../components/iconComponents/NewspaperIcon";
import BoxIcon from "../../../components/iconComponents/BoxIcon";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: "TRAITEMENTS",
    items: [
      {
        title: "Protocoles",
        href: "/app/protocol/view",
        icon: FolderAddIcon,
      },
      {
        title: "Données",
        href: "/app/secondary/view",
        icon: ListIcon,
      },
      {
        title: "Questionnaires",
        href: "/app/survey/view",
        icon: FileDocumentIcon,
      },
    ],
  },
  {
    subheader: "DOCUMENTS",
    items: [
      {
        title: "Consulter les documents",
        href: "/app/documents/view",
        icon: FileDocumentIcon,
      },
      {
        title: "Créer un nouveau document",
        href: "/app/documents/create",
        icon: UnionIcon,
      },
    ],
  },
  {
    subheader: "MARKETPLACE",
    items: [
      {
        title: "Consulter les produits",
        href: "/app/marketplace/view",
        icon: MarketplaceIcon,
      },
      {
        title: "Ajouter un produit",
        href: "/app/marketplace/create",
        icon: UnionIcon,
      },
    ],
  },
  {
    subheader: "CONTENU",
    items: [
      {
        title: "Ajouter du contenu",
        href: "/app/cms/create",
        icon: NewspaperIcon,
      },
      {
        title: "Classer le contenu",
        href: "/app/cms/manage",
        icon: BoxIcon,
      },
    ],
  },
  {
    subheader: "FACTURATION",
    items: [
      {
        title: "Contrats hopital",
        href: "/app/contracts",
        icon: FolderAddIcon,
      },
      {
        title: "Offres professionnels",
        href: "/app/offers",
        icon: FolderAddIcon,
      }
    ],
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerContainer: {
    paddingTop: "40px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.text.secondary,
    "& ul:last-child": {
      marginBottom: "20px",
    },
  },
  mobileDrawer: {
    width: 256,
    height: "calc(100% - 64px)",
  },
  desktopDrawer: {
    width: 290,
    top: "80px !important",
    height: "calc(100% - 64px) !important",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  listSubHeader: {
    "& li": {
      color: `${theme.palette.text.primary} !important`,
      fontWeight: 900,
      fontSize: 20,
    },
  },
  button: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.text.secondary} !important`,
    margin: "50px auto 0 auto !important",
    "text-transform": "none !important",
    padding: "10px 40px !important",
    fontSize: "16px !important",
    borderRadius: "8px !important",
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = createBrowserHistory();
  const hidden = useMediaQuery("(max-width:1200px)");

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ display: { xs: 'none', lg: "none" } }}>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Box>
        <Box p={2} className={classes.drawerContainer}>
          {sections.map((section) => (
            <List
              className={classes.listSubHeader}
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <PrimaryButton
            disabled={false}
            text={"Inviter un professionel"}
            onClick={() => {
              history.push("/app/invite");
              window.location.reload();
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Box hidden={hidden}>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Box>
      <Box hidden={hidden}>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
