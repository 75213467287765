import React from 'react';
import type { FC } from 'react';

interface BoxIconProps {
  [key: string]: any;
}

const BoxIcon: FC<BoxIconProps> = (props) => {
  return (
    <img
      alt="Box icon"
      src="/static/icons/box.svg"
      {...props}
    />
  );
}

export default BoxIcon;
