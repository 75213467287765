import React from 'react';
import type { FC } from 'react';

interface ChevronDownIconProps {
  [key: string]: any;
}

const ChevronDownIconImg: FC<ChevronDownIconProps> = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/icons/black-chevron-down.svg"
      {...props}
    />
  );
}

export default ChevronDownIconImg;
