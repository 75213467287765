import type { FC } from "react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Box,
  Menu,
  MenuItem
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "src/theme";
import useAuth from "src/hooks/useAuth";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountHeader from "./AccountHeader";

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    right: `${theme.spacing(3)} !important`,
    left: "unset !important",
    top: "20px !important",
    width: 180,
    "& ul": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  menuIcon: {
    width: "18px !important",
    height: "18px !important",
    marginRight: 12,
  },
  menuText: {
    fontWeight: "bold !important",
    fontSize: "14px !important",
  },
  avatar: {
    height: "58px !important",
    width: "58px !important",
    marginRight: theme.spacing(2),
  },
  accountName: {
    fontWeight: "800 !important",
  },
  rotatingIcon: {
    filter: "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(129deg) brightness(107%) contrast(101%)",
    marginLeft: theme.spacing(5),
  },
  accountNameMenu: {
    fontWeight: "800 !important",
    color: `${theme.palette.primary.main} !important`,
  },
  rotatingIconMenu: {
    marginLeft: theme.spacing(5),
    transition: ".4s ease-in-out",
    transform: "rotate(180deg)",
    backgroundColor: `${theme.palette.secondary.light} !important`,
    "-webkit-mask": "url(/static/icons/chevron-down.svg) no-repeat center",
    mask: "url(/static/icons/chevron-down.svg) no-repeat center",
    filter: "brightness(0) saturate(100%) invert(61%) sepia(54%) saturate(5366%) hue-rotate(358deg) brightness(98%) contrast(110%)",
    width: 16,
    height: 16,
    "& img": {
      visibility: "hidden",
    },
  },
}));

const Account: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      logout();
      history.push("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"row"}>
        <Avatar alt="User" className={classes.avatar} />
        <AccountHeader
          isOpen={isOpen}
          eventHandler={handleOpen}
          textStyle={classes.accountName}
          iconStyle={classes.rotatingIcon}
        />
      </Box>

      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={anchorEl}
        open={isOpen}
      >
        <AccountHeader
          isOpen={isOpen}
          eventHandler={handleClose}
          textStyle={classes.accountNameMenu}
          iconStyle={classes.rotatingIconMenu}
        />
        <MenuItem onClick={handleLogout} className={classes.menuText}>
          <LogoutOutlinedIcon className={classes.menuIcon} />
          Déconnexion
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
