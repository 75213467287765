import React from 'react';
import type { FC } from 'react';

interface NewspaperIconProps {
  [key: string]: any;
}

const NewspaperIcon: FC<NewspaperIconProps> = (props) => {
  return (
    <img
      alt="Newspaper icon"
      src="/static/icons/newspaper-bold.svg"
      {...props}
    />
  );
}

export default NewspaperIcon;
